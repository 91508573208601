
import {RouteURL, MapsURL, SubscriptionKeyCredential, Aborter, CalculateRouteDirectionsOptions, Models} from 'azure-maps-rest';
import { Map, tileLayer, Marker, LatLng, MarkerOptions, Polyline,  Point, DivIcon } from 'leaflet';
import blueMapIcon from '../assets/marker-hole-blue.svg';
import redMapIcon from '../assets/marker-hole-red.svg';
import greenMapIcon from '../assets/marker-hole-green.svg';

const routeProperties: CalculateRouteDirectionsOptions = {
    routeType: 'shortest' as Models.RouteType,
    instructionsType : Models.RouteInstructionsType.Text,
    computeBestOrder: false
};

const routeDisplayProperties: any = {
    color: '#2272B9',
    weight: 5,
    lineJoin: 'round',
    lineCap: 'round'
};

const markerHolderProperties: any = {
    color: '#000000',
    weight: 2,
    lineJoin: 'round',
    lineCap: 'round'
};

const markerDisplayOptions = {
    markerColor: 'Black',
    routeBeginText: 'S',
    routeEndText: 'E',
    routeFirstStopIdx: 1
}

const NumberedDivIcon = (displayText: string): DivIcon => {
    const mapIcon = displayText === markerDisplayOptions.routeBeginText ?
        greenMapIcon :
        (displayText === markerDisplayOptions.routeEndText ?
            redMapIcon : blueMapIcon);

    return new DivIcon({
        html: `<div class='leaflet-div-icon'>
                     <img src=${mapIcon} alt=''/>
                     <div class='number'>${displayText}</div>
                 </div>`,
        iconSize: new Point(25, 41),
        iconAnchor: new Point(13, 41),
        popupAnchor: new Point(0, -40),
        className: 'leaflet-div-icon'
    })
};

const subscriptionKey = `${process.env.REACT_APP_MAP_AUTH}`;

const getMap = (divId: string): Map => {
    const roadMapTilesUrl = () => {
        return `https://atlas.microsoft.com/map/tile/png?` +
            `api-version=1&layer=basic&style=main&TileFormat=pbf&` +
            `tileSize=512&zoom={z}&x={x}&y={y}&subscription-key=${subscriptionKey}`;
    }
    
    const roads = tileLayer(roadMapTilesUrl(), {
        attribution: '© ' + new Date().getFullYear() + ' Microsoft, © 1992 - ' + new Date().getFullYear() + ' TomTom',
        maxZoom: 18,
        tileSize: 256,
        zoomOffset: 0,
        id: 'azureRoadMaps', 
        crossOrigin: true
    });
    const map = new Map(divId, { layers: [roads] }).setView([33.92021, -84.35632], 13);
    map.zoomControl.setPosition('topright')
    return map;
};

const createRoute = async (mapReference: Map | undefined, locationInformation: {address: string, coordinates: number[]}[], optimize: boolean = true)
: Promise<{travelTimeInSeconds:number|undefined, lengthInMeters:number|undefined, addressOrder: number[]}> => {

    const padding = 0.0022;
    const coordinates = locationInformation.map(({ coordinates }) => (coordinates));
    routeProperties.computeBestOrder = optimize;

    if (!mapReference || locationInformation.length < 1) {
        return {travelTimeInSeconds:0, lengthInMeters:0, addressOrder:[]};
    }
    if (locationInformation.length === 1) {
        addMarkersToMap(mapReference, locationInformation, undefined);
        mapReference.fitBounds([
            [(coordinates[0][1] - padding), (coordinates[0][0] - (padding))],
            [(coordinates[0][1] + padding), (coordinates[0][0] + (padding))]
        ])
        return {travelTimeInSeconds:0, lengthInMeters:0, addressOrder:[]};
    }

    const pipeline = MapsURL.newPipeline(new SubscriptionKeyCredential(subscriptionKey));
    const definedRouteURL = new RouteURL(pipeline);

    const directions = await definedRouteURL.calculateRouteDirections(Aborter.timeout(10000), coordinates, routeProperties);

    const bbox = directions.geojson.getFeatures().bbox;

    if (bbox) {
        mapReference.fitBounds([
            [(bbox[1]), (bbox[0] - (padding/2))],
            [(bbox[3] + padding), (bbox[2] + (padding/2))]
        ])
    }
    if (directions.routes && directions.routes[0].summary) {
        directions.routes[0].legs?.forEach(({ points }: any) => {
            const pointsInLeg = points.map(({latitude, longitude}: {latitude: number, longitude: number})  => {
                return [latitude, longitude];
            })
            new Polyline(pointsInLeg, routeDisplayProperties).addTo(mapReference);
        })

        addMarkersToMap(mapReference, locationInformation, directions.optimizedWaypoints);
        if (directions.optimizedWaypoints) {
            return {
                travelTimeInSeconds: directions.routes[0].summary.travelTimeInSeconds,
                lengthInMeters: directions.routes[0].summary.lengthInMeters,
                addressOrder: directions.optimizedWaypoints.reduce((result: number[], stopRef) => {
                    if (stopRef.optimizedIndex !== undefined) { result.push(stopRef.optimizedIndex); }
                    return result;
                }, [])
            }
        }
        return {
            travelTimeInSeconds: directions.routes[0].summary.travelTimeInSeconds,
            lengthInMeters: directions.routes[0].summary.lengthInMeters,
            addressOrder: locationInformation.slice(1, -1).map((_, idx) => idx)
        };
    }
    return {travelTimeInSeconds:0, lengthInMeters:0, addressOrder:[]};
};

const addMarkersToMap = (map: Map, locations: {address: string, coordinates: number[]}[], optimizedStopInd: Models.RouteOptimizedWaypoint[] | undefined) => {
    const numOfWayPnts = locations.length;

    const fistStop = locations[0];
    const lastStop = locations[numOfWayPnts - 1];

    addMarker(map, markerDisplayOptions.routeBeginText, fistStop);
    addMarker(map, markerDisplayOptions.routeEndText, lastStop);

    if (optimizedStopInd) {
        optimizedStopInd.forEach((stopRef, index) => {
            if (stopRef.optimizedIndex !== undefined) {
                const stopTextNumber = index + markerDisplayOptions.routeFirstStopIdx;
                addMarker(map, stopTextNumber.toString(), locations[stopRef.optimizedIndex + 1]);
            }
        });
    } else {
        for (let wayPntIdx = 0; wayPntIdx < numOfWayPnts - 2; wayPntIdx++) {
            const stopTextNumber = markerDisplayOptions.routeFirstStopIdx + wayPntIdx;
            addMarker(map, stopTextNumber.toString(), locations[wayPntIdx + 1]);
        }
    }
};

const markerDragHandler = (startPoint: LatLng, markerPointer: Polyline) => (e: any) => {
    markerPointer.setLatLngs([startPoint, e.latlng]);
};

const addMarker = (map: Map, text: string, location: {address: string, coordinates: number[]}) => {
    const markerLatLng = new LatLng(location.coordinates[1], location.coordinates[0]);
    const marker = new Marker(markerLatLng, {
        icon: NumberedDivIcon(text),
        draggable: true
    } as MarkerOptions);

    const markerpointer = new Polyline([markerLatLng], markerHolderProperties).addTo(map);

    marker.addTo(map)
          .setTooltipContent(text)
          .bindPopup(location.address);

    const openPopup = () => { marker.openPopup()};

    marker.addEventListener('mouseover', openPopup);
    marker.addEventListener('mouseout', marker.closePopup);

    marker.addEventListener('drag', markerDragHandler(markerLatLng, markerpointer));
}

export { getMap, createRoute }