import {Buffer} from 'buffer';
import  Rijndael from 'rijndael-js';

Buffer.from('anything','base64');



export function decryptStr(ciphertext)
{
    
        const key = '6/kgBmA6S/ZvRi689nGZoniwcJZSwFnnFeHnjdmkyy4=';
        const iv = 'FAt8sFhJIhdWjTertld8kg==';
        
    
        const data = atob(key);
        const karray = Uint8Array.from(data, b => b.charCodeAt(0));
    
        const datai = atob(iv);
        const ivarray = Uint8Array.from(datai, b => b.charCodeAt(0));   
        
        const cipher = new Rijndael(karray, 'cbc');
    
        var buf = Buffer.from(ciphertext, 'base64');
        const plaintext = Buffer.from(cipher.decrypt(buf, 128, ivarray));
        
        
        return parseInt(plaintext.toString());
    
   
    }

   // export{decryptStr};