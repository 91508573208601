import React, { useEffect, useState } from 'react';
//import {Buffer} from 'buffer';
//import  Rijndael from 'rijndael-js';
import {decryptStr} from './common';
import {
    useLocation
} from "react-router-dom";
import { getMap, createRoute } from './mapController';
import { getAuthToken, getGeoFromAddress } from './addressController';
import { Map } from 'leaflet';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";

import { metersToMiles, secondsToFullTime, formatTimeString, checkForChangeInRouteOrder, routeOrderToString } from './utils'

//Buffer.from('anything','base64');

export default function AzureMap() {
    const useURLLocation = useLocation().search;
    const mapEle = React.createRef<HTMLDivElement>();
    const [geoLocations, setGeoLocations] = useState<{ address: string, coordinates: number[] }[]>();
    const [mapReady, setMapReady] = useState(false);
    const [map, setMap] = useState<Map>();
    const [firstCall, setFirstCall] = useState<boolean>(true);
    const [routeTravelTime, setRouteTravelTime] = useState('');
    const [routeTravelDistance, setRouteTravelDistance] = useState('');
    const [routeChanged, setRouteChanged] = useState('');
    const [mappedRoute, setMappedRoute] = useState('');
    const appInsights = useAppInsightsContext();
    //const [address, setAddresses] = React.useState([]);


    

    function changeString(reqParam: any) {
        const words = reqParam.split(" ");
        // console.log(words.length);
        // console.log(words[words.length-2]);
        for (let i = 0; i < words.length; i++) {

            if (i == words.length - 2) {
                words[i] = words[i].toUpperCase();
            } else {
                words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
            }

        }
        return words.join(" ");
    }
    const trackPageLoad = useTrackEvent(appInsights, "Load Page", {
        'route': new URLSearchParams(useURLLocation).get("route"),
        'optimized': new URLSearchParams(useURLLocation).get("optimized"),
        'online_id': new URLSearchParams(useURLLocation).get("online_id")


    });
    const trackGeo = useTrackEvent(appInsights, "Convert GeoCoord", geoLocations);
    const trackMapRoute = useTrackEvent(appInsights, "Set Map Route", { "returnedRoute": mappedRoute });
    const [authTokenDetail, setAuthTokenDetail] = React.useState('');

    const convertAddressToCoords = async (addresses: string[] | undefined): Promise<void> => {
        try {
            console.log(addresses);
            if (addresses === undefined || addresses.length === 0)
                return;

            

            const formattedAddress = addresses.map(address => {
                return { formattedAddress: changeString(address) }
            });
            debugger;
            console.log(formattedAddress)
            const geoLocation = await getGeoFromAddress(formattedAddress, authTokenDetail)
            setGeoLocations(geoLocation.geoLocations);
        } catch (err) {
            console.error(err);
        }
    };




    const getAuthTokenFromController = async () => {
        let authTokenReturn = await getAuthToken();
        if (authTokenReturn)
            setAuthTokenDetail(authTokenReturn);
    }

    useEffect(() => { trackMapRoute({ "returnedRoute": mappedRoute }) }, [mappedRoute, trackMapRoute]);


    useEffect(() => { trackGeo(geoLocations) }, [geoLocations, trackGeo]);


    const getRouteDetails = async () => {
        const routeQuery = new URLSearchParams(useURLLocation).get("route")?.split(/[[\]:]/).slice(3, -1);

        
        if (!geoLocations) {

            const routeAddresses = (routeQuery && routeQuery.length >= 1) ? routeQuery : await getRouteDetailsFromDB();

            //debugger;
            //console.log(routeAddresses)
            convertAddressToCoords(routeAddresses)

        }

    };

    useEffect(() => {
        //const routeQuery = new URLSearchParams(useURLLocation).get("route")?.split(/[[\]:]/).slice(3, -1);
        trackPageLoad({
            'route': new URLSearchParams(useURLLocation).get("route"),
            'optimized': new URLSearchParams(useURLLocation).get("optimized"),
            'online_id': new URLSearchParams(useURLLocation).get("online_id")
        });
        if (!authTokenDetail) {
            getAuthTokenFromController();
            return;
        }

        getRouteDetails();
        //const getRouteDetails = async () => {

        // const routeAddresses =  (routeQuery && routeQuery.length >= 1) ? routeQuery : await getRouteDetailsFromDB();
        //  if (!geoLocations) {
        //      convertAddressToCoords(routeAddresses)
        //  }

        // }; 
        // @ts-ignore: window.external used to grab function from C# application
        //const routeAddresses =  (routeQuery && routeQuery.length >= 1) ? routeQuery : await getRouteDetailsFromDB();

        //if (!geoLocations) {
        // convertAddressToCoords(routeAddresses)
        //}

    }, [geoLocations, useURLLocation, trackPageLoad, authTokenDetail]);

    useEffect(() => {
        if (mapEle?.current?.id && !map) {
            const mapElement = getMap(mapEle.current.id);
            setMap(mapElement);
            mapElement.whenReady(() => {
                setMapReady(true);
            });
        }
    }, [map, mapEle]);

    

    const getRouteDetailsFromDB = async () => {
        
        const online_id_enc = new URLSearchParams(useURLLocation).get("online_id");
        let online_id;
        
        if(online_id_enc){
             online_id = decryptStr(online_id_enc).toString();

            
        }
       
       

        // debugger;
      // console.log(online_id);
    
        var routeDetailsReturn = await fetch(`${process.env.REACT_APP_USA_ADDRESS_API}/Maps/RouteDetails?online_id=` + online_id,
            {
                method: "Get",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authTokenDetail}`
                }


            })
            .then((res) => res.json())

            debugger;
            console.log(routeDetailsReturn)


        return routeDetailsReturn
        

    };





    useEffect(() => {
        const query = new URLSearchParams(useURLLocation).get("optimized");
        //debugger;
        //console.log(query);
        const optimizeRoute = (query?.toLowerCase() === "true");
       // debugger;
       // console.log(query);
        if (mapReady && geoLocations && firstCall) {
            setFirstCall(false);
            createRoute(map, geoLocations, optimizeRoute).then(({ addressOrder = [], travelTimeInSeconds = 0, lengthInMeters = 0 }) => {
                setRouteTravelDistance(metersToMiles(lengthInMeters).toFixed(2));
                setRouteTravelTime(formatTimeString(secondsToFullTime(travelTimeInSeconds)));
                debugger;
                  console.log(addressOrder);
                if (addressOrder) {
                    setRouteChanged(checkForChangeInRouteOrder(addressOrder).toString());
                    setMappedRoute(routeOrderToString(geoLocations, addressOrder));
                }
            });
        }

    }, [mapReady, geoLocations, useURLLocation, map, firstCall]);



    return (
        <>
            <input type="hidden" id="hfOptimizedPath" name="hfOptimizedPath" value={mappedRoute} />
            <input type="hidden" id="hfOptimized" name="hfOptimized" value={routeChanged} />
            <div id='printableArea'>
                <div id="myMap" ref={mapEle} className="map" />
                {(routeTravelDistance || routeTravelTime) ?
                    <div id="infoPanel" className="routeInfoBlock">
                        Total Distance: {routeTravelDistance} miles
                        <br />
                        Total Time:  {routeTravelTime}
                    </div>
                    : null}
            </div>
        </>
    );
};