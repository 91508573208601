import axios from 'axios';
import { isInCanada } from './utils';

const getAuthToken = async () => {
    const userAuth = {
        'macAddress': '2',
        'apiKey': `${process.env.REACT_APP_ADDRESS_AUTH}`,
        'appVersion': '1.0'
      };

    const settings = {'headers': {'Content-Type': 'application/json'}};

    const authToken = await axios.post(
        `${process.env.REACT_APP_USA_ADDRESS_API}/Maps/Authenticate`, 
        userAuth, 
        settings
    );
    return authToken.data;
};

const formatLatLong = ({ GeoCodeResponse }: {GeoCodeResponse: Array<{ Lat: string, Lng: string, Address: string }>}): {address: string, coordinates: number[]}[] => {

    return GeoCodeResponse.map(
        ({ Lat, Lng, Address }) => {
        return {address: Address, coordinates: [ parseFloat(Lng), parseFloat(Lat) ]};
    });
}

const getGeoFromAddress = async (addresses: Array<{ formattedAddress: string }>, apiAuthToken: string, retry: boolean = true): Promise<{ usedAuthToken: string, geoLocations: {address: string, coordinates: number[]}[]}> => {
    let authToken = apiAuthToken;
    const settings = {
        'headers': {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
        }
    };

    const geoURL = (isInCanada(addresses[0].formattedAddress)) ? process.env.REACT_APP_CANADA_ADDRESS_API : process.env.REACT_APP_USA_ADDRESS_API
    
    const addressesBody = { items: addresses };
   debugger;
   console.log(addresses);
    const locationDetails = await axios.post(
        `${geoURL}/Maps/GeoCode`, 
        addressesBody, 
        settings
    );

    if (locationDetails.status === 401 && retry) {
        authToken = await getAuthToken();
        return await getGeoFromAddress(addresses, authToken, false);
    }
    return { usedAuthToken: authToken, geoLocations: formatLatLong(locationDetails.data)};
}

export { getAuthToken, getGeoFromAddress };