const metersToMiles = (meters: number): number => {
    return meters * 0.000621;
}

const secondsToFullTime = (totalSeconds: number): {hours: number, minutes: number, seconds: number} => {
    const remainingTime = {hours: 0, minutes: 0, seconds: 0};
    const totalMinutes = Math.floor(totalSeconds / 60);
    remainingTime.seconds = totalSeconds % 60;
    remainingTime.minutes = totalMinutes % 60;
    remainingTime.hours = Math.floor(totalMinutes / 60);

    return remainingTime;
}

const formatTimeString = ({hours, minutes, seconds}: {hours: number, minutes: number, seconds: number}): string => {
    return String(hours).padStart(2, '0') + ':' + String(minutes).padStart(2, '0') + ':' + String(seconds).padStart(2, '0');
}

const checkForChangeInRouteOrder = (addressOrder: number[]): boolean => {
    for (let index = 0; index < addressOrder.length; index++) {
        if (addressOrder[index] !== index)
            return true;
    };
    return false;
}

const isInCanada = (fullAddress: string): boolean => {
    const addressArray = fullAddress.split(' ');
    const zip = addressArray.pop();
    if (zip && zip.length === 3)
        return true;

    return false;
}


 


const routeOrderToString = (allWaypoints: {address: string}[], addressOrder: number[]): string => {
    const allAddresses = [...allWaypoints];
    
    const startLocation = allAddresses.shift();
    const endLocation = allAddresses.pop();
    const formatLocation = (address: string | undefined) => {
        if (!address)
            return '';
        
        const addressArray = address.split(' ');
        debugger;
       console.log(addressArray);
        if (isInCanada(address))
            return addressArray.slice(0,-2).join(' ') + ' CANADA to:';

        return addressArray.slice(0,-1).join(' ') + ' USA to:';
    }

    let addressString = formatLocation(startLocation?.address);

    addressString += addressOrder.reduce((result, locationIdx): string => {
        result += formatLocation(allAddresses[locationIdx].address);
        return result;
    }, '');
    debugger;
    console.log(addressString);
    addressString += formatLocation(endLocation?.address);
    return addressString;
}

export { metersToMiles, secondsToFullTime, formatTimeString, checkForChangeInRouteOrder, routeOrderToString, isInCanada }