import {
  BrowserRouter as Router,
  Switch, Route
} from "react-router-dom";
import AzureMap from './components/Map';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";

import './App.css'
 
function App() {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Router>
        <Switch>
          <Route exact path="/" component={AzureMap} />
        </Switch>
      </Router>
    </AppInsightsContext.Provider>
  );
}

export default App;